<template>
  <div class="w_auto radius15 " style="width:100%;margin-top: 5px;">
    <div class="bgwhites radius15">
      <div class="btn-color">
          <span class="persty sty1">我的课程实验</span>
      </div>
    </div>
    <div class="mt15 mb15  ">
      <div class="radius15">
        <el-row :gutter="20" class="radius15">         
          <el-col :span="24">
            <div class="bagray bagrayns" >
            <el-row :gutter="20" v-if="trainingList.length!=0">
              <el-col :span="6" v-for="(i, index) in trainingList" :key="index" class="mt10">
                <el-card :body-style="{ padding: '0px' }" class="hovershadow is-always-shadow">
                  <div class="indpic"  v-if="i.curriculumPic1">
                    <img :src="i.curriculumPic1" class="image" @click="subclick(i.curriculumResourceId,i.experimentId)"/>
                  </div>
                   <div class="indpic" v-else>
                    <img :src="onlineImg" class="image" @click="subclick(i.curriculumResourceId,i.experimentId)"/>
                  </div>
                  <div style="padding: 14px">
                    <span class="bt-title" @click="subclick(i.curriculumResourceId,i.experimentId)">课程名称：{{ i.curriculumTheme }}</span>
                    <span class="bt-title" @click="subclick(i.curriculumResourceId,i.experimentId)">实验名称：{{ i.experimentName }}</span>
                    <div class="bottom clearfix">
                      <p>
                        <span>讲师：{{i.teacherName}}</span>
                      </p>
                      <div class="isfree flex-betweens">
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <div v-else>
            <el-empty :image-size="200"></el-empty>
          </div>
          <div class="page pagemb">
            <el-pagination
              background
              class="pagination"
              layout="prev, pager, next"
              :page-size="pageSize"
              :total="count"
              :current-page="page"
              @current-change="handleCurrentChange"
              >
            </el-pagination>
          </div>
          </div>         
         
          </el-col>                 
        </el-row>
      </div>
    </div>
      
  </div>
</template>

<script>
export default {
  data(){
    return{
      token:localStorage.getItem("pc_token"),
      input:'',
      onlineImg:require("@/assets/images/onlinecourses/kecheng2.jpg"),
       options: [
       
        ],
        trainingList:[],
        courseList:[],
        labelIdName:'',
        pageSize:8,
        page: 1,
        count: 0,
    }
  },
  mounted(){
    this.getClientUserCurriculumExperimentList()
  },
  methods:{
    getClientUserCurriculumExperimentList(){
      this.$api.getClientUserCurriculumExperimentList({
        channelId: this.$http.channelId,
        page: this.page,
        limit: this.pageSize,
        token:this.token
      }).then(res => {
        if(res.code == 10000){
          this.trainingList = res.data.data
          this.count = res.data.count
        }
      })
    },
    // 分页
    handleCurrentChange(curPage){
      this.page = curPage
      this.getClientUserCurriculumExperimentList()
    },
    subclick(id,experimentId){
        this.$router.push({
          path:'/course/detailList',
          query:{
            id:id,
            experimentId:experimentId
          }
        })
    }
  }
  
}
</script>

<style scoped lang="scss">
  .radius15{
      border-radius: 10px;
  }
 .sty1 {
    background: url(../../assets/images/personal/course.png) no-repeat;
    background-size: 137px auto;
  }
  .mb15{
        margin-bottom: 15px;
  }
  .persty {
    display: inline-block;
    top: 0;
    left: 0;
    width: 150px;
    height: 29px;
    line-height: 29px;
    padding-left: 15px;
    color: #fff;
}
.bgwhites{
  background-color: #fff;
  padding: 16px;
  .btn-color{
    display: flex;
    align-items: center;   
    font-size: 14px;    
  }
  .btn-color span{
    padding-right:15px;
  }
}
.actives {
  color: #18cca7;
}
.mt15 {
  margin-top: 15px;
  .bagray{
    padding: 10px 20px 15px; 
    background-color: #fafafa; 
    // border-radius: 5px;
    box-shadow: 0px 8px 10px -3px rgb(0 0 0 / 10%) 
  }
  .bagrayns{
    box-shadow:none
  }
  .indpic {
    position: relative;
    .image {
      width: 100%;
      height: 1.07rem;
      display: block;
      cursor: pointer;
    }
    .kcstat {
      position: absolute;
      width: 100%;
      padding: 5px;
      bottom: 0;
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
      font-size: 12px;
    }
    .kcstat i {
      font-size: 20px;
      color: #f00;
      margin-right: 5px;
    }   
  }
  .bottom {
    margin-top: 3px;
    line-height: 12px;
  }
  .bottom p {
    font-size: 12px;
    color: #ccc;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  .isfree {
    margin-top: 10px;
  }
  .isfree span {
    color: #f00;
  }
  .isfree button {
    padding: 7px 10px;
    background: linear-gradient(#f47349 0%, #f0955d 70%);
    border-radius: 3px;
    color: #fff;
  }
  .flex-betweens{
      display: flex;
      justify-content : space-between;
      align-items: center; 
      align-content: center;
    }
  }
.pagination{
  display: flex;
  justify-content: flex-end;
  margin: 1% 0;
}
.hovershadow:hover {
    box-shadow: 0 0 5px 0 #c7c7c7, 0 1px 8px 0 #c7c7c7;
    transform: translateY(-3px);
  }
  .is-always-shadow {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
.bt-title{
  width: 270px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  color: #333;
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
}
.bt-title:hover{
  color: #3ce4bc;
}
.cktj{
  color:#000;
  font-size:12px
}
.courseul{
  display: flex;
}
.courseul li{
  padding: 0 15px;
  cursor: pointer;
}
.page {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.mb20{
  margin-bottom: 5%;
}
.btn-color ::v-deep .el-button--primary{
  background:linear-gradient(#3ce4bc 0%, #68b9cd 70%) !important;
}
.kc-input{
  width: 17%;
}
.clearfix ::v-deep .el-icon-caret-right {
  font-size: 25px;
}
.new-card::v-deep .el-card__header{
  padding: 10px 20px 15px; 
}
.new-card::v-deep .el-card__body {
  padding: 1px;
}
// 排序开始
.btn-color::v-deep .el-tabs__nav-wrap::after{
  height: 0px;
}
.btn-color::v-deep .el-tabs__active-bar{
  height: 0px;
}
.btn-color::v-deep .el-tabs__header{
  margin: 0 0 1px
}
.btn-color::v-deep .el-tabs__item{
  padding: 0 15px;
}
</style>