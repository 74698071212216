<template>
  <div class="w_auto">
    <div class="personal-info pad10 radius10 mt15">
      <span class="pertit font18">个人中心</span>
      <div class="personal-lf">
        <el-row>
          <el-col :span="21">
            <div class="personal-l">
              <img
                v-if="value && value.headPortrait != null"
                class="radius avatarper"
                :src="value.headPortrait"
                alt=""
              />
              <div v-else>
                <img :src="circleUrl" alt="" />
              </div>
              <div class="pertxt">
                <p >
                  {{ value ? value.userName : ''}}
                   <el-divider direction="vertical"></el-divider>
                  手机号：{{value? value.mobilePhone : ''}}
                </p>
                <p class="pertxtp" >                 
                  邮箱：{{value ? value.email : ''}}
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="gotoedit">
              <el-button v-if="grzx" plain @click="jbzlClick"><i class="el-icon-edit"></i> 去修改</el-button>
              <el-button v-if="jbzl" plain @click="grzxClick"><i class="el-icon-arrow-left"></i> 个人中心</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <Personal v-if="jbzl"></Personal>
    <Information v-if="grzx"></Information>
    <el-dialog title="充值" :visible.sync="dialogVisible" width="35%">
      <div v-if="value">
        <el-row>
          <ul class="zfmethod" style="display:flex">
            <li v-for="(item,index) in topUpLis" :key="index" @click="getMoney(index,item.topUpId)" :class="{colorChange:index==dynamic}">
              <p class="point">{{item.topUpPoints}}积分</p>
              <p class="price">售价{{item.topUpPrice}}元</p>
            </li>
          </ul>
        </el-row>
      </div>
          <span slot="footer" class="dialog-footer">
            <el-button style="width:30%" @click="dialogVisible = false">取 消</el-button>
            <el-button style="width:30%" type="primary" @click="integralClick"
            >确 定</el-button
            >
        </span>    
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="wechatVisible"
      width="500px"
      >
      <ul class="zfmethod1 zfmethod2">
        <li class="checked">
          <div class="gou"> 
            <i class="el-icon-check"></i>
          </div>
          <img src="@/assets/images/onlinecourses/weixin.png" alt="">
        </li>
      </ul>
      <div class="payCode">
        <img src="" alt="">
        <p>请扫码支付</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Personal from "./personalData.vue";
import Information from "./information.vue";
export default {
  components: { Personal,Information },
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      value: "",
      topUpLis:[],
      topUpId:'',
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogVisible: false,
      wechatVisible:false,
      dynamic:0,
      jbzl:false,
      grzx:true
    };
  },
  mounted() {
    this.value = JSON.parse(localStorage.getItem("pc_data")) || {};
    this.queryLiveTopUpListIsEnable()
  },
  watch: {
    "$store.state.value": function () {
      //监听vuex中userName变化而改变header里面的值
      let state = this.$store.state.value;
      this.value=state
    },
  },
  methods: {
    // 个人中心按钮
    grzxClick(){
        this.jbzl = false
        this.grzx = true
    },
    // 去修改按钮
    jbzlClick(){
        this.jbzl = true
        this.grzx = false
    },
    getMoney(index,id){
      this.dynamic = index;
      this.topUpId = id
    },
    queryLiveTopUpListIsEnable(){
      this.$api.queryLiveTopUpListIsEnable({
        token:this.token
      }).then(res => {
        if(res.code == 10000){
          this.topUpLis = res.data
        }
      })
    },
    // 积分确定按钮
    integralClick(){
      this.$api.saveLiveTopUpLog({
        topUpPayType:0,
        topUpId:this.topUpId,
        token:this.token,
      }).then(res => {
        if(res.code == 10000){
          this.dialogVisible = false
          this.wechatVisible = true
          let id = res.data.liveTopUpLog.topUpLogId
          this.completePayState(id)
        }
      }).catch((res) => {
        this.$message.warning('请选择充值金额')
      })
    },
    completePayState(id){
      this.$api.completePayState({
        topUpLogId:id,
        payWay: 0,
        token:this.token
      }).then(res => {
        if(res.code == 10000){
          
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.colorChange {
    border: 2px solid #a3fbe5;
}
.personal-info {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  background: #fff url(../../assets/images/personal/personal.png) 100% 100%
    no-repeat;
  background-size: 100% auto;
}
.pertit {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px 20px;
  border-radius: 10px 0 10px 0;
  color: #fff;
  background: linear-gradient(#3ce4bc 0%, #68b9cd 70%);
}
.personal-l {
  display: flex;
  padding: 50px 0 20px 50px;
}
.personal-l img {
  width: 100px;
  height: 100px;
}
.personal-l .pertxt {
  margin-left: 30px;
  margin-top: 20px;
  flex: 1;
  color: #5cc4ca;
}
.pertxt p {
  margin-bottom: 10px;
  font-size: 16px;
}
.pertxtp {
  display: flex;
  align-items: Center;
}
.pertxt img {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
}
.pertxt p span {
  font-size: 24px;
  margin-right: 5px;
  font-weight: 700;
}
.pertxt p button {
  padding: 8px 15px;
  border-radius: 23px;
  color: #fff;
  margin-left: 30px;
  background-color: #37bcc2;
}
.pertxt p button i {
  margin-right: 3px;
}
.gotoedit {
  position: absolute;
  top: 45%;
}
.gotoedit button {
  border: 1px solid #5cc4ca;
  color: #5cc4ca;
  border-radius: 15px;
  padding: 10px 20px;
  margin-right: 20px;
}
.radius {
  border-radius: 50%;
  padding: 0;
  text-align: center;
}
.font18 {
  font-size: 18px;
}
.pad10 {
  padding: 10px 5px;
}
.radius10 {
  border-radius: 10px;
}
.mt15 {
  margin-top: 15px;
  display: inline-block;
}
.zfmethod li {
  width: 32%;
  padding: 15px 0;
  position: relative;
//   border: 2px solid #fff;
  margin: 0 0.5% 5px;
  border-radius: 5px;
  text-align: center;
  background: #eefefb;
//   display: inline-block;
  cursor: pointer;
}
.zfmethod li .point {
  font-size: 22px;
  font-weight: 700;
  color: #4ccacc;
}
.zfmethod li .price {
  font-size: 16px;
  color: #c3c6c5;
}
.dialog-footer{
    display: flex;
    justify-content: space-around;
}
.zfmethod2{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    margin-bottom: 25px;
}
.zfmethod2 li {
    width: 38%;
    position: relative;
}
.checked {
    border: 2px solid red;
}
.zfmethod1 li .gou {
    position: absolute;
    right: 0;
    bottom: -1px;
    font-size: 22px;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 25px solid red;
    border-left: 25px solid transparent;
}
.gou i {
    color: #fff;
    float: left;
    position: absolute;
    bottom: -26px;
    right: 0;
    font-size: 16px;
}
.zfmethod1 img {
    float: left;
    width: 100%;
}
.payCode {
    text-align: center;
}
.payCode img {
    width: 200px;
    height: 200px;
}
</style>